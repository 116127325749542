import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { SelectField, TextareaField } from '@/components/form';
import { useTranslate } from '@/contexts';
import { RiskLevel } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  OverrideRiskLevelMutation,
  OverrideRiskLevelMutationVariables,
} from './__generated__/OverrideRiskLevelMutation';
import { PlayerRiskLevelFormStaticQuery } from './__generated__/PlayerRiskLevelFormStaticQuery';
import {
  RemoveRiskLevelOverrideMutation,
  RemoveRiskLevelOverrideMutationVariables,
} from './__generated__/RemoveRiskLevelOverrideMutation';

const query = graphql`
  query PlayerRiskLevelFormStaticQuery {
    sanityPlayerRiskLevelForm {
      title {
        ...LocaleString
      }
      adjustment {
        ...LocaleString
      }
      note {
        ...LocaleString
      }
      confirm {
        ...LocaleString
      }
    }
  }
`;

const riskLevelMutation = gql`
  mutation OverrideRiskLevelMutation(
    $playerGlobalId: String!
    $riskLevel: RiskLevel!
    $comment: String!
  ) {
    overrideRiskLevel(
      playerGlobalId: $playerGlobalId
      riskLevel: $riskLevel
      comment: $comment
    ) {
      playerGlobalId
      riskLevel
      overriddenRiskLevel
    }
  }
`;

const removeRiskLevelMutation = gql`
  mutation RemoveRiskLevelOverrideMutation(
    $playerGlobalId: String!
    $comment: String!
  ) {
    removeRiskLevelOverride(
      playerGlobalId: $playerGlobalId
      comment: $comment
    ) {
      playerGlobalId
      riskLevel
      overriddenRiskLevel
    }
  }
`;

type FormValues = {
  riskLevel: 'Default' | RiskLevel;
  comment: string;
};

const PlayerRiskLevelForm: FC<{
  playerGlobalId: string;
  riskLevel: RiskLevel;
}> = ({ playerGlobalId, riskLevel }) => {
  const staticData = useStaticQuery<PlayerRiskLevelFormStaticQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerRiskLevelForm;

  const [changePlayerRiskLevelState, changePlayerRiskLevel] =
    useMutation<OverrideRiskLevelMutation, OverrideRiskLevelMutationVariables>(
      riskLevelMutation,
    );
  const [removePlayerRiskLevelState, removePlayerRiskLevel] = useMutation<
    RemoveRiskLevelOverrideMutation,
    RemoveRiskLevelOverrideMutationVariables
  >(removeRiskLevelMutation);

  const defaultValues: FormValues = {
    riskLevel: riskLevel,
    comment: '',
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: FormValues) => {
    if (values.riskLevel === 'Default') {
      return removePlayerRiskLevel({
        playerGlobalId: playerGlobalId,
        comment: values.comment,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          close();
        }
      });
    }

    return changePlayerRiskLevel({
      playerGlobalId: playerGlobalId,
      riskLevel: values.riskLevel,
      comment: values.comment,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !riskLevel) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-1 sm:grid-cols-1s gap-6"
        >
          <SelectField
            name="riskLevel"
            id="playerRiskLevelForm__new-risk-level"
            title={t(form.adjustment)}
            options={[
              {
                label: RiskLevel.High,
                value: RiskLevel.High,
              },
              {
                label: RiskLevel.Medium,
                value: RiskLevel.Medium,
              },
              {
                label: RiskLevel.Low,
                value: RiskLevel.Low,
              },
              {
                label: 'Default',
                value: 'Default',
              },
            ]}
          />
          <TextareaField
            title={t(form.note)}
            name="comment"
            id="playerRiskLevelForm__comment"
            className="grid-cols-1"
            required
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.confirm)}
            disabled={
              changePlayerRiskLevelState.fetching ||
              removePlayerRiskLevelState.fetching
            }
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerRiskLevelForm;
